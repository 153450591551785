<template>
  <div class="cmp-product-price" v-if="showPrices" :class="parentClassRef">
    <ul class="price-list flex-row-nowrap align-items-baseline">
      <li class="default">
        <span>{{ prices.price }}</span>
      </li>
      <li v-if="prices.isSalesAction" class="original salesaction">
        <span>{{ prices.basePrice }}</span>
      </li>      
        <!-- BESPOKE -->
        <li class="li-tooltip" v-if="showToolTip">
          <span id="ctl02_uc_product_information_ctl00_i_info" class="price-tooltip material-icons-outlined" data-toggle="tooltip" :data-original-title="tooltipText">info</span>
        </li>
        <!-- END EBSPOKE -->
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    prices: { type: Object, required: false },
    parentClassRef: { type: String, default: '', required: false },
    // BESPOKE
    showToolTip: { type: Boolean, required: false, default: false }
    // END BESPOKE
  },
  // BESPOKE
  data() {
    return {      
      tooltipText: window.vue.translations.label.label_gross_price_info_tooltip
    }
  },
  // END BESPOKE
  computed: {
    ...mapGetters(['showPrices'])
  }
}
</script>

<style>

</style>
