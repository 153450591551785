<template>
<!-- BESPOKE CONT: Added computed class -->
  <div class="cmp-product-group-matrix" v-if="render" :class="{ 'single-prop':  Object.keys(computedVariants[0].properties).length == 1 }">
    <!-- END BESPOKE CONT -->
      <div class="matrix">
          <div class="matrix-header">
            <div class="matrix-header-row">
              <div class="prod-code">
                <span v-translation="{ type: 'label', code: 'label_product_code' }"></span>
              </div>
              <div class="description">
                <span v-translation="{ type: 'label', code: 'label_matrix_header_product' }"></span>
              </div>

              <div class="properties" v-for="property in properties" v-bind:key="property">
                  {{ property }}
              </div>

              <div v-if="showPrices" class="prod-price">
                <span v-translation="{ type: 'label', code: 'label_matrix_header_price' }"></span>
              </div>

              <!-- BESPOKE -->
              <!-- <div v-if="showOrderProduct" class="prod-stock">
                <span v-translation="{ type: 'label', code: 'label_stock_indicator' }"></span>
              </div> -->
              <!-- END BESPOKE -->
              <div class="order"></div>
            </div>
          </div>
          <div class="matrix-body-row">
          <div class="matrix-row" v-for="variant in computedVariants" v-bind:key="variant.id">
            <div class="prod-code">              
                <span class="prod-code">{{ variant.id }}</span>
            </div>
              <div class="description">
                <span class="prod-desc">{{ variant.shortDescription }}</span>
              </div>

              <div class="properties" v-for="property in properties" v-bind:key="property">
                  {{variant.properties[property]}}
              </div>

              <div class="prod-price">
                <div class="ph-product-price" v-if="variant.prices.length">
                  <utlz-product-price
                    :prices="variant.prices[0]"
                  ></utlz-product-price>
                </div>
              </div>

              <!-- <div v-if="showStock" class="prod-stock">
                <utlz-product-stock
                  :stock="variant.stock"
                  :stockTotal="variant.stock"
                ></utlz-product-stock>
              </div> -->

              <div class="order">
                <utlz-order-product
                  v-if="showOrderProduct"
                  :product="variant"
                  :allowZeroQuantity="true"
                  :useOrderMode="false"
                  @quantityChanged="quantityChanged($event, variant)"
                  @unitsChanged="unitsChanged($event, variant)"
                ></utlz-order-product>
              </div>
          </div>
          </div>
      </div>
      <div v-if="showOrderProduct" class="order-product-btn flex-row-nowrap justify-content-end">
        <!-- BEPSOKE: Added button icon -->
        <a
          @click="addOrderLinesToCart()"
          class="button large theme-primary"
          :class="{ 'disabled': !orderLines.length }">          
          <span class="material-icons-outlined">shopping_cart</span>
          <span v-translation="{ type: 'button', code: 'button_order' }"></span>
          </a>
          <!-- END BESPOKE -->
      </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import OrderProduct from 'src/components/webshop/order-product/OrderProduct.vue';
import ProductPrice from 'src/components/webshop/product-price/ProductPrice.vue';
import ProductStock from 'src/components/webshop/product-stock/ProductStock.vue';
export default {
  components: {
    'utlz-order-product': OrderProduct,
    'utlz-product-price': ProductPrice,
    'utlz-product-stock': ProductStock
  },
  props: {
    variants: { type: Array, required: true }
  },
  data () {
    return {
      orderLines: [],
      render: true
    }
  },
  computed: {
    ...mapGetters(['showStock', 'userLoggedOn', 'showOrderProduct', 'showPrices']),
    properties () {
      if (this.variants.length > 0) {
        return Object.keys(this.variants[0].properties);
      }
      return [];
    },
    // BESPOKE CONT
    // Sort matrix alphabetically
    computedVariants () {
      let variants = [...this.variants];
      var value = parseInt(variants[0].id);
      if (!Number.isNaN(value)) {
        variants = variants.sort((a, b) => parseInt(a.id) - parseInt(b.id));
      } else {
        variants = variants.sort((a, b) => a.id.localeCompare(b.id));
      }
      return variants;
      // END BESPOKE CONT
    }
  },
  methods: {
    quantityChanged ({ computedQuantity, unitCode }, variant) {
      const index = this.orderLines.findIndex(orderLine => orderLine.prod_code === variant.id);
      if (computedQuantity !== 0) {
        if (index === -1) {
          this.orderLines.push({
            prod_code: variant.id,
            ord_qty: computedQuantity,
            unit_code: unitCode,
            prod_comment: ''
          });
        } else if (index > -1) {
          this.orderLines[index] = {
            prod_code: variant.id,
            ord_qty: computedQuantity,
            unit_code: unitCode,
            prod_comment: ''
          };
        }
      } else if (computedQuantity === 0) {
        this.orderLines.splice(index, 1);
      }
    },
    unitsChanged ({ unitCode, quantity, computedQuantity }, variant) {
      // Changing units resets quantity buttons to zero, so delete variant from orderLines
      const index = this.orderLines.findIndex(orderLine => orderLine.prod_code === variant.id);
      if (index > -1) {
        this.orderLines.splice(index, 1);
      }
    },
    addOrderLinesToCart () {
      this.addToCart(this.orderLines)
        .then(res => {
          this.render = false;
          this.$nextTick(() => {
            this.render = true;
            this.orderLines = [];
          });
        });
    },
    ...mapActions('shoppingCart', ['addToCart'])
  }
}
</script>

<style>

</style>
