<template>
  <div class="cmp-product-group">
      <div class="property" v-for="property in properties" v-bind:key="property">
        <span class="select-label">{{ property }}</span>
          <select :name="property.replace(' ', '-')" :id="'select-' + property.replace(' ', '-')" v-model="mainModel[property]" @change="onChange($event)">
              <option v-for="variant in getPropertyValues(property)" :key="variant.key" :value="variant.key">
                  {{ variant.value }}
              </option>
          </select>
      </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  props: {
    variants: { type: Array, required: true }
  },
  data () {
    return {
      mainModel: {}
    }
  },
  watch: {
    productDetails (n, o) {
      this.mainModel = this.GetMainModel(n)
    },
    variants (n, o) {
      this.mainModel = this.GetMainModel(this.productDetails)
    }
  },
  computed: {
    ...mapState('productDetails', ['productDetails']),
    properties () {
      if (this.variants.length > 0) {
        return Object.keys(this.variants[0].properties);
      }
      return [];
    },
    mainProductProperties () {
      if (this.variants.length > 0) {
        let variant = this.variants.find((x) => x.id === this.productDetails.id);
        if (variant) { return variant.properties; }
      }
      return [];
    }
  },
  methods: {
    ...mapActions('productDetails', ['updateShownProduct']),
    onChange (event) {
      this.updateShownProduct(event.target.value);
    },
    GetMainModel (n) {
      var obj = {}

      if (n !== undefined) { Object.keys(this.mainProductProperties).forEach((x) => obj[x] = n.id); }

      return obj;
    },
    getPropertyValues (property) {
      let returnList = [];
      let products = this.variants;
      let ogProperties = this.mainProductProperties;
      let mainProductId = this.productDetails.id;

      let propertyIndex = this.properties.indexOf(property)
      if (propertyIndex > 0) {
        let filterValues = []
        for (let i = 0; i < propertyIndex; i++) {
          let propertyName = this.properties[i];

          filterValues.push({ key: propertyName, value: ogProperties[propertyName] });
        }

        filterValues.forEach(function (x) {
          products = products.filter(function (y) {
            return y.properties[x.key] === x.value;
          })
        });
      }

      products.forEach(function (x) {
        if (x.properties[property] !== ogProperties[property] || x.id == mainProductId) {
          returnList.push({ key: x.id, value: x.properties[property] })
        }
      });

      var filterByCmAndMtr = returnList.filter(val => val.value.indexOf("mtr") > -1 || val.value.indexOf("cm") > -1).length > 0;

      return returnList.filter((value, index, self) => self.findIndex((x) => x.value === value.value) === index)
        .sort(function (x, y) {
          let valA = x.value;
          let valB = y.value;
          
          // Some specifications are prefixed by no. so this is stripped
          if (valA.indexOf('no. ') > -1) {
            valA = parseInt(valA.replace('no. ', ''));
            valB = parseInt(valB.replace('no. ', ''));
          } else {
            valA = valA.toUpperCase();
            valB = valB.toUpperCase();
          }

          // BESPOKE CONT
          // If cm and mtr specification values are found, multiply
          // mtr specifications by a 100 so we can sort them correctly with cm's
          if (filterByCmAndMtr) {

            valA = valA.replace(" CM", "")
            valB = valB.replace(" CM", "")

            if (valA.indexOf(' MTR') > 0) {
              valA = parseFloat(valA.replace(' MTR', ""));
              valA = valA * 100;
            }

            if (valB.indexOf(' MTR') > 0) {
              valB = parseFloat(valB.replace(' MTR', ""));
              valB = valB * 100;
            }

            valA = parseFloat(valA);
            valB = parseFloat(valB);

          }

          // END BESPOKE CONT

          let comparison = 0;
          if (valA > valB) {
            comparison = 1;
          } else if (valA < valB) {
            comparison = -1;
          }
          return comparison;
        });
    }
  }
}
</script>

<style>

</style>
